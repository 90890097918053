import {
  Box,
  Button,
  Checkbox,
  Chip,
  ChipDelete,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Modal,
  ModalDialog,
  Sheet,
  Textarea,
  Typography,
} from "@mui/joy";
import { Grow, alpha, darken } from "@mui/material";
import { arrayToMap } from "@nerdjs/nerd-core";
import { ReactElement, useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { Interactor } from "../../entities/interactor";
import { Stage } from "../../entities/stage";
import {
  formOpenSelector,
  selectedPipelineIDSelector,
  setFormOpen,
} from "../../reducers/rhapsody";
import { useEventStatuses } from "../../redux/eventStatus/eventStatusHooks";
import { usePipeline } from "../../redux/pipeline/pipelineHooks";
import { StageDrawer } from "../../routes/pipeline";

type State = {
  stageID: number;
  history: Stage[];
};

/**
 *
 * @returns {ReactElement} TestPipeline page
 */
export function TestPipeline() {
  const open = useSelector(formOpenSelector("testPipeline"));
  const { eventStatuses } = useEventStatuses();
  const [state, setState] = useState<State>({ stageID: 0, history: [] });
  const dispatch = useDispatch();
  const close = () => {
    dispatch(setFormOpen({ isOpen: false, formID: "testPipeline" }));
  };
  const [quickEditStageID, setQuickEditStageID] = useState<number | null>();
  const pipelineID = useSelector(selectedPipelineIDSelector);
  const { pipeline } = usePipeline(pipelineID);

  if (!pipeline) return <Box />;
  const stages = pipeline?.stages;
  const stagesMap = arrayToMap(stages);
  const currentStage = stages.find((s) => s.id === state?.stageID);
  const upcomingStages = currentStage?.interactors?.reduce<Stage[]>((a, v) => {
    if (v.segueID) a.push(stagesMap[v.segueID]);
    return a;
  }, []);
  const nextStages = stages.reduce<Stage[]>((a, v) => {
    const available = v.available?.split(",").filter((e) => e);
    const excluded = v.excluded?.split(",").filter((e) => e);

    if (
      (available?.includes(`${state.stageID}`) || available?.includes(`*`)) &&
      !excluded?.includes(`${state.stageID}`)
    ) {
      a.push(v);
    }
    return a;
  }, []);
  const eventStatus = eventStatuses?.find(
    (s) => s.mercuryStageID === state.stageID
  );

  function getActions() {
    const ret: ReactElement[] = [];
    if (state?.stageID) {
      nextStages?.forEach((s) => ret.push(getStageButton(s)));
    } else {
      const entryPoints = stages?.filter((s) => s.primary);
      entryPoints?.forEach((s) => ret.push(getStageButton(s)));
    }

    return ret;
  }

  const getInteractor = (i: Interactor) => {
    switch (i.kind) {
      case "file":
        return (
          <Upload
            interactor={i}
            onChange={(e) => {
              console.log(e);
            }}
            files={[]}
          />
        );
      case "signature":
        return (
          <Signature
            interactor={i}
            onChange={(s) => {
              console.log(s);
            }}
          />
        );
      case "download":
        return (
          <Button
            fullWidth
            variant="soft"
            color="neutral"
            startDecorator={i.icon ? <i className={i.icon} /> : undefined}
            endDecorator={<i className="fa-regular fa-arrow-down"></i>}
            onClick={() => {
              window.open(i.description);
            }}
          >
            Download {i.name}
          </Button>
        );
      case "checkbox":
        return (
          <Box sx={{ textAlign: "left" }}>
            <Checkbox
              label={
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    level="body-sm"
                    sx={{ color: "black" }}
                    startDecorator={
                      i.icon ? <i className={i.icon} /> : undefined
                    }
                  >
                    {i.name}
                  </Typography>
                  {i.description ? (
                    <Typography level="body-xs">{i.description}</Typography>
                  ) : undefined}
                </Box>
              }
            />
          </Box>
        );
      case "text":
        return (
          <Textarea
            variant="plain"
            placeholder={i.description}
            startDecorator={
              <Typography
                level="body-sm"
                sx={{ color: "black" }}
                startDecorator={i.icon ? <i className={i.icon} /> : undefined}
              >
                {i.name}
              </Typography>
            }
          />
        );
      case "button":
        return (
          <Button
            onClick={() => {
              setState((s) => ({
                ...s,
                stageID: i.segueID ?? 0,
                history: currentStage
                  ? [...s.history, currentStage]
                  : [...s.history],
              }));
            }}
            key={i.name}
            startDecorator={i.icon ? <i className={i.icon} /> : undefined}
            sx={{
              flex: 1,
              background: i.color,
              "&:hover": {
                background: i.color ? darken(i.color, 0.1) : undefined,
              },
              "&:active": {
                background: i.color ? darken(i.color, 0.2) : undefined,
              },
            }}
          >
            {i.name}
          </Button>
        );
        break;

      default:
        break;
    }
    return (
      <Sheet variant="soft" color="neutral" sx={{ borderRadius: "8px" }}>
        Interactor Not Supported
      </Sheet>
    );
  };

  function getStageButton(stage: Stage) {
    const color = stage.color ?? "#000000";
    return (
      <Button
        size="sm"
        key={stage.id}
        onClick={() => {
          setState((s) => ({
            ...s,
            stageID: stage.id,
            history: currentStage
              ? [...s.history, currentStage]
              : [...s.history],
          }));
        }}
        startDecorator={
          stage.icon ? <img src={stage.icon} height="16" /> : undefined
        }
        sx={{
          color: color,
          background: alpha(color, 0.1),
          "&:hover": { background: alpha(color, 0.2) },
          "&:active": { background: alpha(color, 0.3) },
        }}
      >
        {stage.name}
      </Button>
    );
  }

  const segueButtons = currentStage?.interactors?.filter(
    (e) => e.kind === "button" && e.segueID
  );
  const otherInteractors = [...(currentStage?.interactors ?? [])]
    ?.filter((e) => !(e.kind === "button" && e.segueID))
    .sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

  return (
    <Modal open={open} onClose={close}>
      <ModalDialog layout="center" sx={{ width: "90vw", height: "90vh" }}>
        <DialogTitle
          sx={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <Box>
            Tesing <b>{pipeline?.name}</b>
          </Box>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              startDecorator={<i className="fa-solid fa-pen"></i>}
              color="neutral"
              disabled={!currentStage}
              variant="soft"
              size="sm"
              onClick={() => setQuickEditStageID(currentStage!.id)}
            >
              Quick Stage Edit
            </Button>
            <Button
              startDecorator={<i className="fa-solid fa-rotate-left"></i>}
              color="neutral"
              variant="soft"
              size="sm"
              onClick={() =>
                setState((s) => ({
                  stageID: s.history.length
                    ? s.history[s.history.length - 1].id
                    : 0,
                  history: s.history.filter(
                    (e) => e.id !== s.history[s.history.length - 1].id
                  ),
                }))
              }
            >
              Undo Action
            </Button>
            <Button
              startDecorator={<i className="fa-solid fa-arrows-rotate"></i>}
              color="neutral"
              variant="soft"
              size="sm"
              onClick={() => setState({ stageID: 0, history: [] })}
            >
              Restart
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            position: "relative",
          }}
        >
          <Grow in>
            <Box
              sx={{
                background: "#eceff1",
                height: 740,
                width: 380,
                borderRadius: "32px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "center",
                textAlign: "center",
                gap: 1,
                p: 2,
                position: "relative",
                overflow: "scroll",
              }}
            >
              {currentStage ? (
                <>
                  <Typography
                    startDecorator={
                      <img height={20} src={currentStage?.icon} />
                    }
                    sx={{ color: currentStage?.color }}
                    level="title-lg"
                  >
                    {currentStage?.name}
                  </Typography>
                  <Typography>{eventStatus?.title}</Typography>
                  <Typography level="body-sm">
                    {eventStatus?.defaultGreeting}
                  </Typography>
                  {otherInteractors.length ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      {otherInteractors.map((e) => (
                        <Sheet
                          key={e.id}
                          variant="soft"
                          color="neutral"
                          sx={{ p: 1, borderRadius: "8px", zIndex: 99999 }}
                        >
                          {getInteractor(e)}
                        </Sheet>
                      ))}
                    </Box>
                  ) : (
                    []
                  )}
                  {segueButtons?.length ? (
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      {segueButtons.map((e) => getInteractor(e))}
                    </Box>
                  ) : (
                    []
                  )}
                </>
              ) : (
                <Typography
                  sx={{ fontWeight: 600, opacity: 0.1, mt: 4 }}
                  level="h1"
                >
                  9:41
                </Typography>
              )}
            </Box>
          </Grow>
          {quickEditStageID ? (
            <Grow in>
              <Box sx={{ position: "relative" }}>
                <IconButton
                  onClick={() => setQuickEditStageID(null)}
                  size="sm"
                  variant="solid"
                  sx={{
                    position: "absolute",
                    top: -20,
                    right: -20,
                    zIndex: 9999,
                    borderRadius: 20,
                  }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </IconButton>
                <Sheet
                  variant="outlined"
                  sx={{
                    maxHeight: 740,
                    overflowY: "scroll",
                    position: "relative",
                  }}
                >
                  <StageDrawer stageID={quickEditStageID} />
                </Sheet>
              </Box>
            </Grow>
          ) : (
            []
          )}
        </DialogContent>
        <Divider>Contractor Actions</Divider>
        <DialogActions sx={{ height: 32, justifyContent: "center" }}>
          {getActions()}
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}

function Upload({
  files,
  onChange,
  interactor,
}: {
  files: File[];
  onChange: (e: File[]) => void;
  interactor: Interactor;
}) {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onChange(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
      <Box
        {...getRootProps()}
        sx={{
          padding: 1,
          borderRadius: 4,
          background: isDragActive ? "#bbdefb" : "f5f5f5",
        }}
      >
        <input {...getInputProps()} />
        <Typography level="h3">
          <i className="fa-duotone fa-cloud-arrow-up"></i>
        </Typography>
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            startDecorator={
              interactor.icon ? <i className={interactor.icon} /> : undefined
            }
            level="title-lg"
          >
            {interactor.name}
          </Typography>
        </Box>
        <Typography level="body-sm">{interactor.description}.</Typography>
        <Link
          level="body-sm"
          endDecorator={<i className="fa-solid fa-arrow-up-right"></i>}
        >
          Browse Files
        </Link>
      </Box>

      {files?.map((f) => (
        <Chip
          sx={{ background: "white" }}
          endDecorator={
            <ChipDelete
              onClick={() => onChange(files.filter((e) => e.size !== f.size))}
            />
          }
          key={f.size}
        >
          {f.name}
        </Chip>
      ))}
    </Box>
  );
}

function Signature({
  onChange,
  interactor,
}: {
  onChange: (s: any) => void;
  interactor: Interactor;
}) {
  // const [value, setValue] = useState<any>();
  const [clear, setClear] = useState(0);
  const ref: any = useRef();

  const handleChange = () => {
    const base64 = ref.current.toDataURL();
    const base64Data = base64.split(",")[1];

    // Convert the base64 data to binary data
    const binaryData = atob(base64Data);

    // Create a Uint8Array from the binary data
    const uint8Array = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Create a Blob from the Uint8Array
    const blob = new Blob([uint8Array], { type: "image/png" });

    // Create a File object from the Blob
    const file = new File([blob], "image.png", { type: "image/png" });

    onChange(file);
  };

  return (
    <Box sx={{ p: 0.5, textAlign: "left" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            startDecorator={
              interactor.icon ? <i className={interactor.icon} /> : undefined
            }
            level="body-sm"
            sx={{ color: "black" }}
          >
            {interactor.name}
          </Typography>
          <Typography level="body-xs">{interactor.description}</Typography>
        </Box>
        <Chip
          variant="outlined"
          onClick={() => {
            ref.current.clear();
          }}
          sx={{ background: "white" }}
          size="sm"
        >
          Clear Signature
        </Chip>
      </Box>
      <Divider />
      <Box sx={{ position: "relative", height: 200 }}>
        <Box
          sx={{ position: "absolute", top: 0, textAlign: "center", zIndex: 10 }}
        >
          <SignatureCanvas
            ref={ref}
            penColor="black"
            onEnd={handleChange}
            canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
          />
        </Box>
        <Typography
          level="h4"
          sx={{
            position: "absolute",
            top: 70,
            opacity: 0.1,
            zIndex: 0,
            margin: "auto",
            textAlign: "center",
            width: "100%",
          }}
        >
          Sign Here
        </Typography>
      </Box>
    </Box>
  );
}
